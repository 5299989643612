<template>
  <div>
    <loading :active.sync="isLoading" :full-poge="false"></loading>
    <b-row class="pb-2 px-2">
      <b-col cols="6">
        <button class="btn btn-sm button-primary mt-2" @click="onXml()">
          <feather-icon size="15" icon="DownloadIcon" class="mr-50" />
          <span style="font-size: 15px">Descargar XLS</span>
        </button>
        <button
          class="btn btn-sm btn-primary mt-2 ml-1"
          @click="onRegisterKeops()"
          v-if="isAdmin"
        >
          <span style="font-size: 15px">Registrar en Keops</span>
        </button>
        <button
          class="btn btn-sm btn-primary ml-1 mt-2"
          @click="onBill()"
          v-if="itemsSelect.length > 0"
        >
          <feather-icon size="15" icon="DollarSignIcon" class="mr-50" />
          <span style="font-size: 15px">Facturar</span>
        </button>
      </b-col>
      <b-col cols="6">
        <filter-bar-component
          ref="search"
          v-on:search="onSearch"
        ></filter-bar-component>
      </b-col>
    </b-row>

    <full-table-component
      ref="fulltable"
      :fields="fields"
      :perPage="100"
      :url="url"
      @selectTo="selectTo"
    >
      <template slot="buttons-slot" slot-scope="props">
        <b-dropdown
          class="p-0"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon size="20" icon="ListIcon" />
          </template>

          <b-dropdown-item @click="onView(props.rowData)">
            <feather-icon size="20" icon="SearchIcon" />
            Ver detalles
          </b-dropdown-item>

          <b-dropdown-item @click="onPdf(props.rowData)">
            <feather-icon size="20" icon="FileIcon" />
            Factura
          </b-dropdown-item>

          <b-dropdown-item
            @click="onReversePayment(props.rowData)"
            v-if="
              props.rowData.status == 'PAGADA' &&
              props.rowData.type == 'post-pago' &&
              isAdmin
            "
          >
            <feather-icon size="20" icon="CreditCardIcon" />
            Revertir Pago
          </b-dropdown-item>

          <b-dropdown-item
            @click="onUndo(props.rowData)"
            v-if="
              props.rowData.status == 'PAGADA' &&
              props.rowData.type == 'pre-pago' &&
              isAdmin
            "
          >
            <feather-icon size="20" icon="RotateCcwIcon" />
            Devolver Dinero
          </b-dropdown-item>

          <b-dropdown-item @click="onDelete(props.rowData)" v-if="isAdmin">
            <feather-icon size="20" icon="TrashIcon" />
            Eliminar
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </full-table-component>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FilterBarComponent from "@/layouts/components/FilterBarComponent";
import FullTableComponent from "@/layouts/components/FullTableComponent";

import AlertService from "@/services/AlertService";
import UserService from "@/services/UserService";
import UtilService from "@/services/UtilService";
import BalanceService from "@/services/BalanceService";

import BalanceView from "./BalanceView";
import PaymentComponent from "./PaymentComponent";
import BalanceXls from "./BalanceXls";
import * as _ from "lodash";
import KeopComponentVue from "./KeopComponent.vue";

export default {
  name: "BalanceAll",
  components: {
    FilterBarComponent,
    FullTableComponent,
  },
  mounted() {
    if (this.$route.query.open) {
      this.onView({
        id: this.$route.query.open,
      });
    }
  },
  data() {
    let fields = [
      {
        name: "id",
        title: "Id Balance",
        sortField: "id",
      },
      {
        name: "created_at",
        title: "Creado",
        sortField: "created_at",
        formatter: (value) => {
          return UtilService.date(value);
        },
      },
      {
        name: "transactions",
        title: "Id Operacion",
        formatter: (value) => {
          return _.truncate(value.join(", "), { length: 25 });
        },
      },
      {
        name: "customer",
        title: "Tienda",
        sortField: "customer",
      },
      {
        name: "vat_condition",
        title: "Condición IVA",
        sortField: "vat_condition",
      },
      {
        name: "voucher_type",
        title: "Tipo de comprobante",
        sortField: "voucher_type",
      },
      {
        name: "invoice_number",
        title: "NRO. factura",
        sortField: "invoice_number",
      },
      {
        name: "total",
        title: "Total",
        sortField: "total",
        formatter: (value) => {
          return this.$options.filters.currency(value);
        },
      },
      {
        name: "status",
        title: "PAGO",
        sortField: "status",
      },
      {
        name: "buttons-slot",
        title: "Acciones",
        titleClass: "text-right",
        dataClass: "text-right align-middle",
      },
    ];

    if (
      this.type === "post-pago" ||
      (this.type === "pre-pago" && UserService.isAdminOrStaff())
    ) {
      fields = [
        {
          name: "__checkbox2",
          titleClass: "center aligned",
          dataClass: "center aligned",
          isDisabled: (value) => {
            // return value.status !== "PENDIENTE";
            return !value.isPending;
          },
        },
      ].concat(fields);
    }
    return {
      isLoading: false,
      fields: fields,
      itemsSelect: [], // items seleccionados para facturar
      isAdmin: UserService.isAdminOrStaff(),
    };
  },
  props: {
    type: String,
  },
  methods: {
    /**
     *
     */
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },

    /**
     *
     */
    onReversePayment(item) {
      if (item.type !== "post-pago" && item.status === "PAGADA") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            text: `No puedes revertir el pago de este balance`,
            icon: "AlertOctagonIcon",
            variant: "danger",
          },
        });
        return;
      }

      AlertService.question(
        "¿Estás seguro?",
        "¿Esta seguro de querer reveritr el pago del balance " + item.id + "?"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          BalanceService.reversePayment(item)
            .then(() => {
              this.isLoading = false;
              AlertService.info();
              this.$refs.fulltable.refresh();
            })
            .catch((error) => {
              this.isLoading = false;
              console.error(error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  text: `Error al tratar de realizar la devolución`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },

    /**
     *
     */
    onUndo(item) {
      if (item.type !== "pre-pago" && item.status === "PAGADA") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            text: `No puedes hacer una devoluciona a este balance`,
            icon: "AlertOctagonIcon",
            variant: "danger",
          },
        });
        return;
      }
      // Desabilitar usuario
      AlertService.questionText(
        "¿Estás seguro?",
        "Por favor escribe el motivo por el cual quieres hacerle un reintegro al ID Balance " +
          +item.id +
          "?",
        {
          inputValidator: (value) => {
            if (value === "") {
              return "Para hacer el reintegro es necesario que escribas el motivo.";
            }
          },
        }
      ).then((result) => {
        if (result.value !== undefined) {
          this.isLoading = true;
          BalanceService.refundOfMoney(item, {
            reason: result.value,
          })
            .then(() => {
              this.isLoading = false;
              AlertService.info();
              this.$refs.fulltable.refresh();
            })
            .catch((error) => {
              this.isLoading = false;
              console.error(error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  text: `Error al tratar de realizar la devolución`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },

    /**
     *
     */
    async onXml(item) {
      this.$modal.show(
        BalanceXls,
        {
          item,
          onSave: {
            handler: ({ year, month }) => {
              this.isLoading = true;
              const search = this.$refs.search
                ? this.$refs.search.filterText
                : "";
              BalanceService.export({ search, year, month })
                .then((response) => {
                  window.open(process.env.VUE_APP_API + '/operation_export/' + response.data.id, "_blank");
                  this.isLoading = false;
                })
                .catch((error) => {
                  console.error(error);
                  this.isLoading = false;
                });
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onView(item) {
      this.$modal.show(
        BalanceView,
        {
          item,
          onRetry: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar el balance con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        BalanceService.destroy(item)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    onRegisterKeops() {
      this.$modal.show(
        KeopComponentVue,
        {
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    onBill() {
      this.$modal.show(
        PaymentComponent,
        {
          items: this.itemsSelect,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    onPdf(item) {
      this.isLoading = true;
      BalanceService.pdf(item.id)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const fileName = "comprobante.pdf";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.showErrorBil = true;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Facturación`,
              text: `La Factura aun no ha sido emitida. Por Favor reintentar en algunas horas. Gracias.`,
              icon: "AlertTriangleIcon",
              variant: "waring",
            },
          });
        });
    },

    /**
     *
     */
    selectTo(items) {
      this.itemsSelect = items;
    },
  },
  computed: {
    url: function () {
      if (this.type) {
        return "/closure-operational?type=" + this.type;
      }
      return "/closure-operational";
    },
  },
};
</script>

<style lang="scss" scoped></style>
